import Vue from "vue";
import { API_REQUEST } from "@/core/services/store/api.module";
import ApiService from "@/core/services/api.service";

export default Vue.extend({
  data() {
    return {
      doneTypingInterval: 500,
      typingTimer: null,
      suggestedVariants: [],
    };
  },

  methods: {
    clearSuggestedVariants() {
      this.suggestedVariants = [];
    },

    requestSuggestionVariants(suggestionProcessorBeanName, query, flag) {
      clearTimeout(this.typingTimer);

      this.typingTimer = setTimeout(() => {
        if (flag === undefined) {
          flag = null;
        }

        const params = { query: query, flags: flag };
        if (ApiService.teamData) {
          params["teamId"] = ApiService.teamData.value;
        }

        this.$store
          .dispatch(API_REQUEST, {
            resource: "/platform/api/suggestion/" + suggestionProcessorBeanName,
            requestType: "GET",
            requestParams: params,
          })
          .then((response) => {
            this.suggestedVariants = response;
          });
      }, this.doneTypingInterval);
    },

    getVariantNameById(
      suggestionProcessorName,
      query,
      flag,
      successCallbackFunction
    ) {
      if (!query) {
        this.suggestedVariants = [];
        return;
      }

      this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/suggestion/" + suggestionProcessorName,
          requestType: "GET",
          requestParams: { query: query, flags: flag },
        })
        .then((response) => {
          successCallbackFunction(response);
          this.suggestedVariants = response;
        });
    },

    // TODO: remove
    // Set suggestion box input through DOM directly. Use this method only if ref is not working.
    setSuggestionBoxInputValue(domId, value) {
      document.getElementById(domId).firstChild.childNodes[1].value = value;
    },
  },
});
