<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                :label="$t('COMMON.TYPE')"
                label-for="product-type"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.productTypeId"
                  id="product-type"
                  :options="productTypes"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="field-reference"
                label-cols-lg="2"
                :label="$t('PRODUCTS.REFERENCE')"
                label-for="field-reference"
              >
                <CompactSuggestion
                  ref="productROSuggestionProcessorRef"
                  suggestion-processor="productROSuggestionProcessor"
                  v-model="dto.referenceObjectId"
                  :flag="dto.productTypeId"
                  :placeholder="$t('PRODUCTS.CHOOSE_PRODUCT')"
                >
                </CompactSuggestion>
              </b-form-group>

              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name-input"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-active"
                label-cols-lg="2"
                :label="$t('COMMON.ACTIVE')"
                label-for="field-active-input"
              >
                <b-form-checkbox
                  id="checkbox-active-input"
                  v-model="dto.active"
                  name="checkbox-active"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-primary"
                label-cols-lg="2"
                :label="$t('PRODUCTS.PRIMARY')"
                label-for="field-primary-input"
              >
                <b-form-checkbox
                  id="checkbox-primary-input"
                  v-model="dto.primary"
                  name="checkbox-active"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                :label="$t('PRODUCTS.OWNERSHIP')"
                label-for="ownership-type"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.ownershipTypeId"
                  id="currency-type"
                  :options="ownershipTypes"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="field-lifetime"
                label-cols-lg="2"
                :label="$t('PRODUCTS.LIFETIME')"
                label-for="field-lifetime-input"
              >
                <b-form-input
                  id="field-body-input"
                  v-model="dto.lifetime"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-deferment"
                label-cols-lg="2"
                :label="$t('PRODUCTS.DEFERMENT')"
                label-for="field-deferment-input"
              >
                <b-form-input
                  id="field-body-input"
                  v-model="dto.deferment"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-price"
                label-cols-lg="2"
                :label="$t('PRODUCTS.PRICE')"
                label-for="field-price-input"
              >
                <b-form-input
                  id="field-price-input"
                  v-model="dto.priceAsFractional"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                :label="$t('ACCOUNTS.CURRENCY')"
                label-for="currency-type"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.currencyId"
                  id="currency-type"
                  :options="currencies"
                >
                </b-form-select>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  mixins: [SuggestionProcessorMixin],
  name: "product",
  components: { CompactSuggestion },
  data() {
    return {
      save: {
        resource: "/platform/api/product/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        productTypeId: null,
        referenceObjectId: null,
        name: null,
        locale: null,
        active: false,
        primary: false,
        ownershipTypeId: null,
        lifetime: null,
        deferment: null,
        priceAsFractional: null,
        currencyId: null,
      },

      productTypeRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.market.model.ProductType",
        requestType: "GET",
        requestParams: {},
      },
      currencyTypeRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.account.model.Currency",
        requestType: "GET",
        requestParams: {},
      },
      ownershipTypeRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.market.model.OwnershipType",
        requestType: "GET",
        requestParams: {},
      },

      productTypes: [],
      products: [],
      currencies: [],
      ownershipTypes: [],
      showOverlay: true,
      createNewStr: this.$t("PRODUCTS.CREATE_NEW_PRODUCT"),
      editStr: this.$t("PRODUCTS.EDIT_PRODUCT"),
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PRODUCTS"), route: "/products" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params != null && this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/product/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }
    if (this.dto.id != null) {
      let myFunc = (response) => {
        this.$refs.productROSuggestionProcessorRef.setText(response[0].name);
      };

      this.getVariantNameById(
        "productROSuggestionProcessor",
        this.dto.referenceObjectId,
        this.dto.productTypeId,
        myFunc
      );
    }

    this.showOverlay = false;

    this.loadProductTypes();
    this.loadOwnershipTypes();
    this.loadCurrency();
    //            this.loadProducts();
  },

  methods: {
    loadProductTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.productTypeRequest)
        .then((response) => {
          for (const value of response) {
            this.productTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },
    loadCurrency: function () {
      this.$store
        .dispatch(API_REQUEST, this.currencyTypeRequest)
        .then((response) => {
          for (const value of response) {
            this.currencies.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },
    loadOwnershipTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.ownershipTypeRequest)
        .then((response) => {
          for (const value of response) {
            this.ownershipTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },
    loadProducts: function () {
      this.$store
        .dispatch(API_REQUEST, this.productRequest)
        .then((response) => {
          for (const value of response) {
            this.products.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    onSaveClicked: function () {
      this.errors = [];
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
