<template>
  <vue-typeahead-bootstrap
    v-model="queryStr"
    :minMatchingChars="1"
    :show-on-focus="true"
    trim
    :disabled="disabled"
    :size="size"
    @input="onInput(suggestionProcessor, queryStr, flag)"
    :data="suggestedVariants"
    :serializer="serializer"
    @hit="handleHit"
    :placeholder="placeholder"
  >
    <template slot="suggestion" slot-scope="{ data }">
      <div class="align-items-center">
        <!-- Note: the v-html binding is used, as htmlText contains
             the suggestion text highlighted with <strong> tags -->
        <!--<span class="ml-4" v-html="htmlText"></span>-->
        <span>{{ data.name }}</span>
        <template v-if="data.description && data.description !== ''">
          <br />
          <span>{{ data.description }}</span>
        </template>
      </div>
    </template>
  </vue-typeahead-bootstrap>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "compactSuggestion",
  props: {
    suggestionProcessor: {
      type: String,
      required: true,
    },
    placeholder: String,
    serializer: {
      type: Function,
      default: (s) => s.name,
    },
    size: {
      type: String,
      default: "md",
    },
    flag: {
      type: String,
      default: undefined,
    },

    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withoutTeamId: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      queryStr: "",
      doneTypingInterval: 500,
      typingTimer: null,
      suggestedVariants: [],
    };
  },

  created: function () {
    this.requestSuggestionVariants(
      this.suggestionProcessor,
      this.queryStr,
      this.flag
    );
  },

  methods: {
    onInput(suggestionProcessorBeanName, query, flag) {
      clearTimeout(this.typingTimer);

      this.typingTimer = setTimeout(() => {
        this.requestSuggestionVariants(
          suggestionProcessorBeanName,
          query,
          flag
        );
      }, this.doneTypingInterval);
    },

    requestSuggestionVariants: function (
      suggestionProcessorBeanName,
      query,
      flag
    ) {
      if (flag === undefined) {
        flag = null;
      }

      let params = {
        flags: flag,
        query: query,
      };

      if (!this.withoutTeamId) {
        params.teamId = ApiService.teamData ? ApiService.teamData.value : null;
      }

      ApiService.query(
        "/platform/api/suggestion/" + suggestionProcessorBeanName,
        { params: params }
      )
        .then((response) => {
          this.suggestedVariants = response.data;
        })
        .catch(this.onError);
    },

    onError: function (response) {
      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    handleHit: function (evt) {
      this.$emit("hit", evt);
      this.$emit("input", evt.id);
    },

    setText: function (text, val) {
      this.queryStr = text;
      if (val !== undefined) {
        this.$emit("input", val);
      }
      this.requestSuggestionVariants(
        this.suggestionProcessor,
        this.queryStr,
        this.flag
      );
    },

    clearValue: function () {
      this.$emit("input", null);
      if (this.queryStr === "") return;
      this.queryStr = "";
      this.suggestedVariants = [];

      this.requestSuggestionVariants(
        this.suggestionProcessor,
        this.queryStr,
        this.flag
      );
    },
  },
};
</script>
